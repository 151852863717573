<template>
  <div>
    <el-dialog title="修改试卷信息"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="80px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="试卷名称">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_name" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="试卷学期">
              <el-select v-model="form.semester"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in semesterList"
                           :key="item.id"
                           :label="item.label"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="试卷类型">
              <el-select v-model="form.edu_paper_type"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in paperTypeList"
                           :key="item.paper_type_id"
                           :label="item.paper_type_name"
                           :value="item.paper_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="试卷时长">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_duration" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="开始时间">
              <el-date-picker v-model="form.paper_time"
                              style="width:100%"
                              type="datetime"
                              @focus="$forbid"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="截止时间">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              @focus="$forbid"
                              type="datetime"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>
        </el-row>
        <p>
          说明：
        </p>
        <p>
          1.试卷可以定时开始训练，也可以不选时间，这样学生任何时间都可以训练；
        </p>
        <p>
          2.组的试卷必须提交才能导出或提供给学生做题。
        </p>
        <!-- <p>
          3.指定年级和指定班级二选一
        </p> -->

        <!-- <p>
          （注：试卷类型添加“模考统考”，由上到下依次为“单元检测”、“月考联考”、“期中考试”、“期
          末考试”、“模考统考”）
        </p> -->
        <el-row :gutter="10"
                style="text-align:center;margin-top:20px">
          <el-col :span="24">
            <el-button type="primary"
                       @click="save">提交</el-button>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      subjectKey: 'subject_id',
      form: {},
      dialogVisible: false,
      paperTypeList: [],
      semesterList: [
        { label: '上学期', value: 0 },
        { label: '下学期', value: 1 },
        { label: '未指定', value: 2 },
      ],
      rules: {
        paper_name: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      },
      year: [],
      semesterList: [
        { id: 0, label: '上学期' },
        { id: 1, label: '下学期' },
        { id: 2, label: '未指定' },
      ]
    }
  },
  mounted () {

  },
  methods: {

    getPaperTypeList (form) {
      let obj = {
        edu_paper_id: form.edu_paper_id,
        paper_name: form.paper_name,
        semester: form.semester,
        edu_paper_type: form.edu_paper_type,
        paper_duration: form.paper_duration,
        end_time: form.end_time,
        paper_time: form.paper_time
      }
      this.$set(this, 'form', obj)
      this.$forceUpdate()
      if (form.type == 2) {
        var params = {
          grade_id: form.grade_id,
          subject_id: form.subject_id
        }
      } else if (form.type == 3) {
        var params = {
          special_class_subject_id: form.subject_id
        }
      }
      this.$http({
        url: '/api/v1/public/paper_type',
        method: 'GET',
        params
      }).then(res => {
        if (res.data.paper_type_id) {
          this.paperTypeList = [res.data]
        } else {
          this.paperTypeList = Object.values(res.data)
        }
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      this.$http({
        url: '/api/v1/combination/whole_edit',
        method: 'post',
        data: this.form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.handleClose()
        this.$parent.search()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  font-size: 14px;
  div {
    margin-left: 15px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>